<template>
  <div class="bg-triangles">
    <main class="main">
      <div class="container gutter-top">
        <!-- Header -->
        <header class="header box">
          <div class="header__left">
            <div class="header__photo">
              <img
                v-if="item.profile_image"
                class="header__photo-img"
                :src="getImageUrl(item.profile_image, 'small')"
                :alt="getImageAlt(item.profile_image)"
                width="320"
                height="320"
              />
            </div>
            <div class="header__base-info">
              <h4 class="title titl--h4">
                {{ item.first_name }}
                <span class="weight--400">
                  {{ item.middle_name }} {{ item.last_name }}
                </span>
              </h4>
              <div class="status">
                {{ item.headline }}
              </div>
              <ul class="header__social">
                <li>
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://go.adityacprtm.dev/facebook"
                    title="Facebook"
                  >
                    <em class="font-icon icon-facebook1"></em>
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://go.adityacprtm.dev/twitter"
                    title="Twitter"
                  >
                    <em class="font-icon icon-twitter1"></em>
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://go.adityacprtm.dev/instagram"
                    title="Instagram"
                  >
                    <em class="font-icon icon-instagram1"></em>
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://go.adityacprtm.dev/linkedin"
                    title="linkedIn"
                  >
                    <em class="font-icon icon-linkedin1"></em>
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://go.adityacprtm.dev/github"
                    title="Github"
                  >
                    <em class="font-icon icon-github1"></em>
                  </a>
                </li>
                <li>
                  <router-link to="/journey">
                    <em class="font-icon icon-flag" title="Journey"></em>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="header__right">
            <ul class="header__contact">
              <li>
                <span class="overhead"> {{ header.one }} </span>
                <a
                  href="https://hidemail.adityacprtm.dev/m/q-1le"
                  target="_blank"
                  rel="noopener"
                  title="Email"
                >
                  a...@fake.mail
                  <em class="font-icon icon-external-link"></em>
                </a>
              </li>
              <li>
                <span class="overhead"> {{ header.two }} </span>
                {{ item.address }}
              </li>
            </ul>
            <ul class="header__contact">
              <li>
                <span class="overhead"> {{ header.three }} </span>
                <a
                  target="_blank"
                  rel="noopener"
                  href="https://go.adityacprtm.dev/cv"
                  title="CV"
                >
                  Download <em class="font-icon icon-download"></em>
                </a>
              </li>
              <li>
                <span class="overhead"> {{ header.four }} </span>
                <a
                  :class="{ disable_locale: disableLocaleID }"
                  @click="localeID"
                  href="#"
                >
                  ID
                </a>
                <span>|</span>
                <a
                  :class="{ disable_locale: disableLocaleEN }"
                  @click="localeEN"
                  href="#"
                >
                  EN
                </a>
                <em class="font-icon icon-info" :title="header.tooltip"></em>
              </li>
            </ul>
          </div>
        </header>

        <div class="row sticky-parent">
          <!-- Sidebar nav -->
          <aside class="col-12 col-md-12 col-lg-2">
            <div class="sidebar box sticky-column">
              <ul class="nav">
                <li class="nav__item">
                  <router-link to="/" exact>
                    <em class="icon-user"></em> {{ this.sidebar.about }}
                  </router-link>
                </li>
                <li class="nav__item">
                  <router-link to="/resume">
                    <em class="icon-file-text"></em> {{ this.sidebar.resume }}
                  </router-link>
                </li>
                <li class="nav__item">
                  <router-link to="/portfolio">
                    <em class="icon-codesandbox"></em>
                    {{ this.sidebar.portfolio }}
                  </router-link>
                </li>
                <li class="nav__item">
                  <router-link to="/blog">
                    <em class="icon-book-open"></em> {{ this.sidebar.blog }}
                  </router-link>
                </li>
                <li class="nav__item">
                  <router-link to="/contact">
                    <em class="icon-book"></em> {{ this.sidebar.contact }}
                  </router-link>
                </li>
              </ul>
            </div>
          </aside>

          <!-- Content -->
          <div class="col-12 col-md-12 col-lg-10">
            <div class="box box-content">
              <keep-alive exclude="BlogDetail,Series,SeriesList">
                <router-view />
              </keep-alive>
            </div>

            <!-- Footer -->
            <footer class="footer">
              <!-- {{
                item.footer
                  ? item.footer
                  : "© 2020 Adityacprtm. All Rights Reserved."
              }} -->
              <span v-if="uptime.status">
                <em
                  class="font-icon icon-check-circle"
                  style="color: green"
                ></em>
                {{ uptime.upText }}, by
              </span>
              <span v-else>
                <em class="font-icon icon-x-circle" style="color: red"></em>
                {{ uptime.downText }}, by
              </span>
              <a
                target="_blank"
                rel="noopener"
                href="https://uptime.adityacprtm.dev"
                title="Uptime"
              >
                Uptime
              </a>
            </footer>
            <!-- Footer -->
          </div>
        </div>
      </div>
    </main>

    <!-- SVG masks -->
    <svg class="svg-defs">
      <clipPath id="avatar-box">
        <path
          d="M1.85379 38.4859C2.9221 18.6653 18.6653 2.92275 38.4858 1.85453 56.0986.905299 77.2792 0 94 0c16.721 0 37.901.905299 55.514 1.85453 19.821 1.06822 35.564 16.81077 36.632 36.63137C187.095 56.0922 188 77.267 188 94c0 16.733-.905 37.908-1.854 55.514-1.068 19.821-16.811 35.563-36.632 36.631C131.901 187.095 110.721 188 94 188c-16.7208 0-37.9014-.905-55.5142-1.855-19.8205-1.068-35.5637-16.81-36.63201-36.631C.904831 131.908 0 110.733 0 94c0-16.733.904831-37.9078 1.85379-55.5141z"
        />
      </clipPath>
      <clipPath id="avatar-hexagon">
        <path
          d="M0 27.2891c0-4.6662 2.4889-8.976 6.52491-11.2986L31.308 1.72845c3.98-2.290382 8.8697-2.305446 12.8637-.03963l25.234 14.31558C73.4807 18.3162 76 22.6478 76 27.3426V56.684c0 4.6805-2.5041 9.0013-6.5597 11.3186L44.4317 82.2915c-3.9869 2.278-8.8765 2.278-12.8634 0L6.55974 68.0026C2.50414 65.6853 0 61.3645 0 56.684V27.2891z"
        />
      </clipPath>
    </svg>
  </div>
</template>

<script>
import { localeDetection } from "./mixins/localeDetection"
import { helper } from "./mixins/helper"

export default {
  name: "App",
  mixins: [localeDetection, helper],
  data() {
    return {
      item: {},
      sidebar: {
        about: "About",
        resume: "Resume",
        portfolio: "Portfolio",
        blog: "Blog",
        contact: "Contact",
      },
      header: {
        one: "Email",
        two: "Location",
        three: "CV",
        four: "Language",
        tooltip: "Choose language",
      },
      disableLocaleEN: false,
      disableLocaleID: false,
      uptime: {
        status: true,
        upText: "All systems are operational",
        downText: "Partially systems are down",
      },
    }
  },
  created() {
    this.axios.defaults.params = {}
    this.axios.defaults.params["_locale"] = this.getLocale()

    if (this.getLocale() == "id") {
      this.disableLocaleID = "true"

      this.sidebar.about = "Tentang"
      this.sidebar.resume = "Resume"
      this.sidebar.portfolio = "Portofolio"
      this.sidebar.blog = "Blog"
      this.sidebar.contact = "Kontak"

      this.header.one = "Email"
      this.header.two = "Lokasi"
      this.header.three = "CV"
      this.header.four = "Bahasa"
      this.header.tooltip = "Pilih Bahasa"
    } else {
      this.disableLocaleEN = "true"
    }

    this.getGeneral()
    this.getUptime()
  },
  methods: {
    async getGeneral() {
      this.axios.get("/general").then((response) => (this.item = response.data))
    },
    localeID() {
      localStorage.locale_adityacprtm = "id"
      window.location.reload()
    },
    localeEN() {
      localStorage.locale_adityacprtm = "en"
      window.location.reload()
    },
    getUptime() {
      this.axios({
        url: "https://raw.githubusercontent.com/Adityacprtm/uptime/master/history/summary.json",
        params: "",
        headers: "",
      }).then((response) => {
        let up = 0
        let down = 0
        for (const value of response.data) {
          if (value.status != "up") {
            down += 1
          } else {
            up += 1
          }
        }
        if (up == response.data.length && down == 0) {
          this.uptime.status = true
        } else {
          this.uptime.status = false
        }
      })
    },
  },
  metaInfo() {
    return {
      title: "Adityacprtm | DevOps & SRE",
      // titleTemplate: "%s Adityacprtm | DevOps & SRE",
      htmlAttrs: {
        lang: "en",
      },
      meta: [
        {
          vmid: "description",
          name: "description",
          content:
            "Get to know Aditya Chamim Pratama | Adityacprtm | DevOps & SRE",
        },
        {
          name: "twitter:card",
          content: "summary_large_image",
        },
        {
          name: "twitter:url",
          content: "https://adityacprtm.dev/",
        },
        { name: "twitter:site", content: "@adityacprtm" },
        { name: "twitter:creator", content: "@adityacprtm" },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          content: "Adityacprtm | DevOps & SRE",
        },
        {
          vmid: "twitter:description",
          name: "twitter:description",
          content:
            "Get to know Aditya Chamim Pratama | Adityacprtm | DevOps & SRE",
        },
        {
          vmid: "twitter:image",
          name: "twitter:image",
          content: this.item.social_image
            ? this.item.social_image.url
            : "/static/social.jpg",
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: "Adityacprtm | DevOps & SRE",
        },
        { property: "og:type", content: "website" },
        {
          property: "og:url",
          content: "https://adityacprtm.dev/",
        },
        {
          vmid: "og:image",
          property: "og:image",
          content: this.item.social_image
            ? this.item.social_image.url
            : "/static/social.jpg",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content:
            "Get to know Aditya Chamim Pratama | Adityacprtm | DevOps & SRE",
        },
        { property: "og:site_name", content: "adityacprtm.dev" },
      ],
    }
  },
}
</script>
