import Vue from "vue"
import App from "./App.vue"
import "./registerServiceWorker"
import router from "./router"
import * as Sentry from "@sentry/vue"
import { Integrations } from "@sentry/tracing"

Vue.config.productionTip = false

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_DSN_SENTRY,
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  })
}

// Importing the global css file
import "@/assets/css/style.css"
import "@/assets/css/style-dark.css"

new Vue({
  router,
  render: function (h) {
    return h(App)
  },
}).$mount("#app")
